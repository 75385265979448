var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ErrorAlert", {
        attrs: { dismissible: "" },
        on: { dismiss: _vm.onDismiss },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [_vm._v(" " + _vm._s(_vm.message) + " ")]
            },
            proxy: true
          },
          {
            key: "dismiss",
            fn: function() {
              return [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "danger", size: "sm" },
                    on: { click: _vm.goToBilling }
                  },
                  [_vm._v("Billing")]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }